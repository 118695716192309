*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

ul[class],
ol[class] {
	padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

body {
	min-height: 100vh;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
	background-color: white;
}

ul[class],
ol[class] {
	list-style: none;
}

a:not([class]) {
	text-decoration-skip-ink: auto;
}

img {
	max-width: 100%;
	display: block;
}

article > * + * {
	margin-top: 1em;
}

input,
button,
textarea,
select {
	font: inherit;
}
