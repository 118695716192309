@import "var";

.game-desktop {
	width: 100vw;
	height: calc(100vh - 60px); // vh - (nav height)
	min-height: 42rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.chat-room {
		background-color: $light;
		width: 100%;
		height: 100%;
		padding: 1.2rem;
		box-shadow: 0px 0px 12px 2px rgba($accent, 0.25);
		z-index: 1;
		// min-height: 48rem;
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: space-around;
		// overflow-y: auto;

		.prize-pool {
			margin-top: 1rem;
			height: 20%;
			//min-height
			.label {
				font-size: 1.5rem;
				color: $dark;
			}
			.prize {
				display: flex;
				align-items: center;
				margin-top: -1rem;
				font-size: 4rem;
				font-weight: 700;
				color: darken($accent, 15%);
				.amount {
					margin-left: 0.5rem;
				}
				.ghd {
					font-size: 2rem;
					padding: 0 0.5rem;
					border-radius: 0.6rem;
					background-color: darken($accent, 15%);
					color: $light;
				}
			}
		}

		.chat {
			opacity: 0.6;
			height: 50%;
			width: 100%;
			background-color: rgba(darken($light, 40%), 0.3);
			border-radius: 0.5rem;
			.chat-text {
				height: 90%;
				width: 100%;
				overflow-y: auto;
				overflow-x: hidden;
			}
			.chat-input {
				height: 10%;
				width: 100%;
				display: flex;
				align-items: center;
				input {
					font-size: 1rem;
					width: 85%;
					height: 100%;
					border: none;
					border-radius: 0 0 0 0.5rem;
					padding: 0.75rem 1rem;
					color: $light;
					background-color: lighten($dark, 10%);
					box-shadow: 0px -4px 8px 0 rgba($accent, 0.25);
					&:focus {
						outline: none;
					}
				}
				button {
					width: 15%;
					height: 100%;
					background-color: $accent;
					border: none;
					border-radius: 0 0 0.5rem 0;
					box-shadow: 0px -4px 8px 0 rgba($accent, 0.25);
					display: flex;
					justify-content: center;
					cursor: pointer;
					align-items: center;
					svg {
						margin-left: -0.25rem;
						margin-bottom: -0.25rem;
						fill: $light;
					}
				}
			}
		}
	}

	.chessboard {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 1rem;
		.chess-piece {
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.game-info {
		background-color: $light;
		width: 100%;
		height: 100%;
		padding: 1.2rem;
		box-shadow: 0px 0px 12px 2px rgba($accent, 0.25);
		z-index: 1;

		.ant-layout-sider-children {
			.ant-skeleton {
				margin-bottom: 2rem;
			}
		}

		.pgn {
			width: 100%;
			height: 40%;
			padding: 1rem;
			overflow: auto;
			.row {
				display: grid;
				grid-template-columns: 1fr 2fr 2fr;
				.cell {
					text-align: center;
					font-size: 1rem;
					margin: 0.25rem;
					padding: 0.5rem 0;
					border-radius: 0.5rem;
				}
				.cell-1 {
					background: rgba($dark, 0.8);
					color: $light;
				}
				.cell-2 {
					background: rgba($accent, 0.3);
					color: $dark;
					font-weight: 600;
				}
			}
		}
		.players {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			width: 100%;
			height: 15%;
			padding: 0 0.5rem;
			.player-info {
				padding: 1rem;
				border-radius: 0.5rem;
				width: 100%;
				display: flex;
				align-items: flex-end;
				.username {
					color: darken($accent, 15%);
					font-size: 1.5rem;
					font-weight: 500;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.elo {
					color: $dark;
					font-size: 0.8rem;
					font-weight: 500;
					margin-left: 1.5rem;
					margin-bottom: 0.35rem;
				}
			}

			.turn {
				background-color: rgba($accent, 0.25);
			}
			.fallen-peice-self {
				margin-top: 0.5rem;
			}
			.fallen-peice {
				padding: 0.75rem;
				display: flex;
				width: 100%;
				svg {
					width: 20px;
					height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 0.25rem;
				}
				.peice {
					display: flex;
					margin-right: 1rem;
					& > * {
						margin-right: -0.75rem;
					}
				}
				#none {
					display: none;
				}
			}
		}
		.btns {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: calc(30% - 1rem);
			button {
				height: 4rem;
				width: 30%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 0.3rem;
				cursor: pointer;
				border: none;
				border-radius: 0.5rem;
				background-color: $accent;

				.text {
					display: none;
					margin-top: 0.1rem;
					font-size: 0.8rem;
					font-weight: 700;
					transition: display 0.2s ease-in-out;
				}
				&:hover {
					.text {
						display: block;
					}
				}
			}
			.win,
			.draw {
				color: $dark;
				svg {
					fill: $dark;
					path {
						fill: $dark;
						stroke: $dark;
					}
				}
			}
			.abort {
				background-color: rgb(255, 64, 64);
				color: $light;
				svg {
					fill: $light !important;
				}
			}
		}
	}
}

.game-tablet {
	width: 100vw;
	height: calc(100vh - 60px);
	display: flex;
	justify-content: space-around;
	align-items: center;
	.chessboard {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.board {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: 1rem 0;
		}
	}
	.game-meta {
		box-shadow: 0px 0px 12px 2px rgba($accent, 0.25);
		background-color: $light;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: space-between;

		.tabs-container {
			padding: 1.2rem;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-around;
			color: $dark;
			.ant-tabs-content-holder {
				.ant-tabs-content {
					height: 100% !important;
					.game-info {
						background-color: $light;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						height: 100%;
						.pgn {
							width: 100%;
							height: 40%;
							padding: 1rem;
							overflow: auto;
							.row {
								display: grid;
								grid-template-columns: 1fr 2fr 2fr;
								.cell {
									text-align: center;
									font-size: 1rem;
									margin: 0.25rem;
									padding: 0.5rem 0;
									border-radius: 0.5rem;
								}
								.cell-1 {
									background: rgba($dark, 0.8);
									color: $light;
								}
								.cell-2 {
									background: rgba($accent, 0.3);
									color: $dark;
									font-weight: 600;
								}
							}
						}
						.players {
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: flex-start;
							width: 100%;
							height: 25%;
							padding: 0 0.5rem;
							.player-info {
								padding: 1rem;
								border-radius: 0.5rem;
								width: 100%;
								display: flex;
								align-items: flex-end;
								.username {
									color: darken($accent, 15%);
									font-size: 1.5rem;
									font-weight: 500;
									overflow: hidden;
									text-overflow: ellipsis;
								}
								.elo {
									color: $dark;
									font-size: 0.8rem;
									font-weight: 500;
									margin-left: 1.5rem;
									margin-bottom: 0.35rem;
								}
							}
							.turn {
								background-color: rgba($accent, 0.25);
							}
							.fallen-peice-self {
								margin-top: 0.5rem;
							}
							.fallen-peice {
								padding: 0.75rem;
								display: flex;
								width: 100%;
								svg {
									width: 20px;
									height: 20px;
									display: flex;
									justify-content: center;
									align-items: center;
									margin-right: 0.25rem;
								}
								.peice {
									display: flex;
									margin-right: 1rem;
									& > * {
										margin-right: -0.75rem;
									}
								}
								#none {
									display: none;
								}
							}
						}
						.btns {
							display: flex;
							justify-content: space-between;
							align-items: center;
							width: 100%;
							height: 25%;
							button {
								height: 4rem;
								width: 30%;
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								padding: 0.3rem;
								cursor: pointer;
								border: none;
								border-radius: 0.5rem;
								background-color: $accent;

								.text {
									display: none;
									margin-top: 0.1rem;
									font-size: 0.8rem;
									font-weight: 700;
									transition: display 0.2s ease-in-out;
								}
								&:hover {
									.text {
										display: block;
									}
								}
							}
							.win,
							.draw {
								color: $dark;
								svg {
									fill: $dark;
									path {
										fill: $dark;
										stroke: $dark;
									}
								}
							}
							.abort {
								background-color: rgb(255, 64, 64);
								color: $light;
								svg {
									fill: $light !important;
								}
							}
						}
					}

					.chat-room {
						height: 100%;
						//min-height -> calc
						display: flex;
						flex-direction: column;
						align-items: left;
						justify-content: space-around;
						.prize-pool {
							margin-top: 1rem;
							height: 20%;
							//min-height
							.label {
								font-size: 1.5rem;
								color: $dark;
							}
							.prize {
								display: flex;
								align-items: center;
								margin-top: -1rem;
								font-size: 4rem;
								font-weight: 700;
								color: darken($accent, 15%);
								.amount {
									margin-left: 0.5rem;
								}
								.ghd {
									font-size: 2rem;
									padding: 0 0.5rem;
									border-radius: 0.6rem;
									background-color: darken($accent, 15%);
									color: $light;
								}
							}
						}

						.chat {
							opacity: 0.6;
							height: 50%;
							width: 100%;
							background-color: rgba(darken($light, 40%), 0.3);
							border-radius: 0.5rem;
							.chat-text {
								height: 90%;
								width: 100%;
								overflow-y: auto;
								overflow-x: hidden;
							}
							.chat-input {
								height: 10%;
								width: 100%;
								display: flex;
								align-items: center;
								input {
									font-size: 1rem;
									width: 85%;
									height: 100%;
									border: none;
									border-radius: 0 0 0 0.5rem;
									padding: 0.75rem 1rem;
									color: $light;
									background-color: lighten($dark, 10%);
									box-shadow: 0px -4px 8px 0 rgba($accent, 0.25);
									&:focus {
										outline: none;
									}
								}
								button {
									width: 15%;
									height: 100%;
									background-color: $accent;
									border: none;
									border-radius: 0 0 0.5rem 0;
									box-shadow: 0px -4px 8px 0 rgba($accent, 0.25);
									display: flex;
									justify-content: center;
									cursor: pointer;
									align-items: center;
									svg {
										margin-left: -0.25rem;
										margin-bottom: -0.25rem;
										fill: $light;
									}
								}
							}
						}
						.btns {
							display: flex;
							justify-content: space-between;
							align-items: center;
							width: 100%;
							height: 25%;
							button {
								height: 4rem;
								width: 30%;
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								padding: 0.3rem;
								cursor: pointer;
								border: none;
								border-radius: 0.5rem;
								background-color: darken($accent, 15%);
								color: $light;
								svg {
									fill: $light;
								}

								.text {
									display: none;
									margin-top: 0.1rem;
									font-size: 0.8rem;
									font-weight: 700;
									transition: display 0.2s ease-in-out;
								}
								&:hover {
									.text {
										display: block;
									}
								}
							}
							.danger {
								background-color: rgb(255, 64, 64);
								color: $light;
							}
						}
					}
				}
			}
		}
	}
}

.game-mobile {
	width: 100vw;
	height: calc(100vh - 60px);
	display: flex;
	justify-content: space-around;
	align-items: center;
	.chessboard {
		// background-color: green;
		width: 85%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		#gameBoard {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 1.5rem 0;
		}
		.players {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
			height: 25%;
			.player-info {
				padding: 0.75rem;
				border-radius: 0.5rem;
				width: 60%;
				display: flex;
				flex-direction: column;
				.username {
					color: darken($accent, 15%);
					font-size: 1.5rem;
					font-weight: 500;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.elo {
					color: $dark;
					font-size: 0.8rem;
					font-weight: 500;
				}
			}
			.turn {
				background-color: rgba($accent, 0.25);
			}

			.fallen-peice {
				padding: 0.75rem;
				display: flex;
				width: 100%;
				svg {
					width: 20px;
					height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 0.25rem;
				}
				.peice {
					display: flex;
					margin-right: 1rem;
					& > * {
						margin-right: -0.75rem;
					}
				}
				#none {
					display: none;
				}
			}
		}
	}
}

.game-meta {
	.ant-drawer-content-wrapper {
		.ant-drawer-content {
			margin-top: 40px;
			height: calc(100vh - 40px);
			.ant-drawer-wrapper-body {
				.ant-drawer-header {
					background-color: $dark;
					.ant-drawer-header-title {
						button {
							margin-top: 1rem;
							span {
								svg {
									fill: $light;
								}
							}
						}
					}
				}
				.ant-drawer-body {
					.tabs-container {
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: space-around;
						color: $light;
						.ant-tabs-content-holder {
							.ant-tabs-content {
								height: 100% !important;
								.game-info {
									background-color: $dark;
									display: flex;
									flex-direction: column;
									justify-content: center;
									height: 100%;
									.pgn {
										width: 100%;
										height: 65%;
										padding: 1rem;
										overflow: auto;
										.row {
											display: grid;
											grid-template-columns: 1fr 2fr 2fr;
											.cell {
												text-align: center;
												font-size: 1rem;
												margin: 0.25rem;
												padding: 0.5rem 0;
												border-radius: 0.5rem;
											}
											.cell-1 {
												background: rgba($light, 0.8);
												color: $dark;
											}
											.cell-2 {
												background: rgba($accent, 0.9);
												color: $light;
												font-weight: 600;
											}
										}
									}
									.btns {
										display: flex;
										justify-content: space-between;
										align-items: center;
										width: 100%;
										height: 25%;
										button {
											height: 4rem;
											width: 30%;
											display: flex;
											flex-direction: column;
											justify-content: center;
											align-items: center;
											padding: 0.3rem;
											cursor: pointer;
											border: none;
											border-radius: 0.5rem;
											background-color: $accent;

											.text {
												display: none;
												margin-top: 0.1rem;
												font-size: 0.8rem;
												font-weight: 700;
												transition: display 0.2s ease-in-out;
											}
											&:hover {
												.text {
													display: block;
												}
											}
										}
										.win,
										.draw {
											color: $dark;
											svg {
												fill: $dark;
												path {
													fill: $dark;
													stroke: $dark;
												}
											}
										}
										.abort {
											background-color: rgb(255, 64, 64);
											color: $light;
											svg {
												fill: $light !important;
											}
										}
									}
								}

								.chat-room {
									height: 100%;
									//min-height -> calc
									display: flex;
									flex-direction: column;
									align-items: left;
									justify-content: space-between;
									.prize-pool {
										height: 20%;
										//min-height
										.label {
											font-size: 1.2rem;
											color: $light;
										}
										.prize {
											display: flex;
											align-items: center;
											margin-top: -1rem;
											font-size: 3rem;
											font-weight: 700;
											color: $accent;
											.amount {
												margin-left: 0.5rem;
											}
											.ghd {
												font-size: 1.5rem;
												padding: 0 0.5rem;
												border-radius: 0.6rem;
												background-color: $accent;
												color: $light;
											}
										}
									}

									.chat {
										opacity: 0.6;
										height: 60%;
										width: 100%;
										background-color: rgba(lighten($dark, 50%), 0.3);
										border-radius: 0.5rem;
										.chat-text {
											height: 88%;
											width: 100%;
											overflow-y: auto;
											overflow-x: hidden;
										}
										.chat-input {
											height: 12%;
											width: 100%;
											display: flex;
											align-items: center;
											input {
												font-size: 1rem;
												width: 85%;
												height: 100%;
												border: none;
												border-radius: 0 0 0 0.5rem;
												padding: 0.75rem 1rem;
												color: $light;
												background-color: lighten($dark, 10%);
												box-shadow: 0px -4px 8px 0 rgba($accent, 0.25);
												&:focus {
													outline: none;
												}
											}
											button {
												width: 15%;
												height: 100%;
												background-color: $accent;
												border: none;
												border-radius: 0 0 0.5rem 0;
												box-shadow: 0px -4px 8px 0 rgba($accent, 0.25);
												display: flex;
												justify-content: center;
												cursor: pointer;
												align-items: center;
												svg {
													margin-left: -0.25rem;
													margin-bottom: -0.25rem;
													fill: $light;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
