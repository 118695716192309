@import "var";

.lobby {
	color: $dark;
	width: 100vw;
	height: calc(100vh - 60px);
	user-select: none;

	.play {
		margin: 0 10rem;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		min-height: 25rem;
		button {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 1.5rem 2rem;
			border-radius: 1.5rem;
			border: none;
			background-color: $accent;
			color: $light;
			text-shadow: 0px 0px 6px rgba($dark, 0.35);
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
			cursor: pointer;
			font-size: 2rem;
			user-select: none; /* supported by Chrome and Opera */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Firefox */
			-ms-user-select: none;
			.btn-text {
				margin-left: 1rem;
				font-weight: 700;
			}
		}
	}

	@media screen and (max-width: 1200px) {
		.play {
			margin: 0 auto;
			width: 90%;
			flex-direction: column;
			button {
				flex-direction: row;
				padding: 1rem 2rem;
			}
		}
	}

	.separator {
		width: 90%;
		background-color: rgba($accent, 0.4);
		border-radius: 50%;
		height: 3px;
		margin: 0 auto;
		margin-bottom: 2rem;
	}
	.spectate-wrapper {
		margin-top: 1.2rem;
		.spectate {
			margin: 0 auto;
			width: 85%;
			min-width: 40rem;
			height: 80%;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			flex-wrap: wrap;
			cursor: pointer;
			border-radius: 1rem;
			padding: 2rem;
			.game {
				border-radius: 1rem;
				// padding: 1rem 4rem;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: center;
				cursor: pointer;
				.board {
					margin: 0.5rem 0;
					transition: all 0.2s ease-in-out;
					border-radius: 8px;
				}
				.p {
					width: 100%;
					display: flex;
					align-items: center;
					margin-left: 2px;
					.username {
						color: lighten($dark, 5%);
						font-size: 1rem;
						font-weight: 500;
						margin-right: 0.75rem;
						transition: all 0.2s ease-in-out;
					}
					.elo {
						font-size: 0.8rem;
					}
				}
				&:hover {
					.board {
						box-shadow: 0px 0px 16px 0px lighten($color: $dark, $amount: 60%);
					}
					.p {
						.username {
							color: darken($accent, 20%);
						}
					}
				}
			}
		}
	}
}
