@import "var";

.Stakes {
	position: relative;
	width: 100vw;
	height: 80vh;
	min-height: 45rem;
	margin-top: 5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	.loader {
		background-color: rgba($dark, 0.75);
		width: 100vw;
		height: 100vh;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
		margin-bottom: 0.1rem;
	}
	.amounts {
		width: 60%;
		height: 30%;
		min-height: 10rem;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		.balance {
			display: flex;
			position: relative;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.label {
				font-size: 2.5rem;
				font-weight: 600;
				color: $dark;
				&::after {
					content: "";
					display: block;
					margin: 0 auto;
					// position: absolute;
					border-radius: 50%;
					width: 100%;
					height: 2px;
					background-color: rgba($accent, 0.6);
					top: 30%;
				}
			}
			.amount {
				margin-top: 0.75rem;
				font-size: 6rem;
				color: $accent;
				font-weight: 800;
			}
		}
	}
	.stake-unstake {
		width: 80%;
		min-height: 480px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		.card {
			padding: 1rem;
			position: relative;
			background-color: $dark;
			width: 40%;
			min-width: 360px;
			min-height: 23rem;
			// border: 1px solid black;
			border-radius: 1rem;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			box-shadow: 0px 0px 20px 1px rgba($dark, 0.15);
			.title {
				font-size: 1.6rem;
				font-weight: 600;
				color: $light;
				margin-bottom: 1rem;
				&::after {
					content: "";
					display: block;
					position: absolute;
					border-radius: 50%;
					width: 70%;
					height: 2px;
					background-color: rgba($accent, 0.8);
					top: 25%;
					left: 15%;
				}
			}
			.input {
				width: 80%;
				height: 5rem;
				background-color: $light;
				border-radius: 0.5rem;
				padding: 1rem;
				color: $dark;
				display: flex;
				justify-content: space-between;
				align-items: center;
				box-shadow: 0px 0px 12px 3px rgba($accent, 0.65);

				.token {
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 1rem;
					font-weight: 500;
					color: $light;
					background-color: $accent;
					padding: 0.5rem;
					border-radius: 0.5rem;
				}
				.amount {
					font-size: 2rem;
					font-weight: 600;
					padding-left: 2rem;
					width: 60%;
					border: none;
					background-color: #fff;
					color: $dark;

					-moz-appearance: textfield;
					&::-webkit-inner-spin-button,
					&::-webkit-outer-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
					&:focus {
						outline: none;
					}
				}
				.max {
					cursor: pointer;
					border: 2px solid $dark;
					background-color: transparent;
					border-radius: 5px;
					padding: 0.3rem;
					font-weight: 600;
					transition: all 0.2s ease-in-out;
					&:hover {
						background-color: $dark;
						border: 2px solid $accent;
						color: $light;
					}
				}
			}
			.submit {
				width: 80%;
				button {
					width: 100%;
					height: 5rem;
					background-color: rgba($accent, 0.7);
					color: $light;
					font-size: 2rem;
					text-transform: uppercase;
					font-weight: 600;
					border-radius: 0.5rem;
					padding: 1rem;
					border: none;
					cursor: pointer;
					transition: all 0.2s ease-in-out;
					&:hover {
						background-color: rgba($accent, 0.9);
					}
				}
			}
		}
	}
}

//mediaquery 1200
@media screen and (max-width: 1024px) {
	.Stakes {
		height: auto;
		.amounts {
			flex-direction: column;
			min-height: 8rem;
			justify-content: center;
			.balance {
				flex-direction: row;
				.label {
					&::after {
						display: none;
					}
				}
				.amount {
					margin-top: 0;
					font-size: 4rem;
					font-weight: 700;
					margin-left: 1.5rem;
				}
			}
		}
		.stake-unstake {
			flex-direction: column;
			margin-top: 2rem;
			.card {
				margin-top: 2rem;
				width: 100%;
				min-width: 400px;
				max-width: 540px;
			}
		}
	}
}

// if (winSize.width < 1200)
// 		return (
// 			<MobileView
// 				isMobileDrawerVisible={isMobileDrawerVisible}
// 				setIsMobileDrawerVisible={setIsMobileDrawerVisible}
// 				isPlayerWhite={isPlayerWhite}
// 				setIsPlayerWhite={setIsPlayerWhite}
// 			/>
// 		);
// 	else if (winSize.width >= 1200 && winSize.width < 1600)
// 		return (
// 			<TabView
// 				isPlayerWhite={isPlayerWhite}
// 				setIsPlayerWhite={setIsPlayerWhite}
// 			/>
// 		);
// 	else
// 		return (
// 			<DesktopView
// 				isPlayerWhite={isPlayerWhite}
// 				setIsPlayerWhite={setIsPlayerWhite}
// 			/>
// 		);
